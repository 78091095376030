import React from "react";
//import Chip from "@material-ui/core/Chip";
//import Avatar from "@material-ui/core/Avatar";
//import {ThumbnailImage} from "cms-toolbox"

export default {
    price: function(a, b) {
        if (a.priceOnRequest) {
            return <span style={{ color: "orange" }}>{"on request"}</span>;
        }
        const nm = ~~a.price;
        if (a.currency) {
            try {
                return nm.toLocaleString("en", {
                    style: "currency",
                    currency: a.currency
                });
            } catch (e) {}
        }
        return nm;
    },

    /*money: (r, key) => {
        const currency = r.currency||"EUR";
       // if (currency) {
            try {
                return parseFloat(r[key]).toLocaleString("en", {
                    style: "currency",
                    currency
                });
            } catch (e) {}
        //}
        return "?";
    },*/
    volume: (r, key) => `${r[key].toFixed(2)} m³`,
    arrayLength: (r, key) => r[key].length,
    fullname: r => r.firstname + " " + r.lastname,
    title: r => r.title,
    url: (r, key) => (
        <a href={r[key]} target="_blank" rel="noopener noreferrer">
            {r[key]}
        </a>
    ),
    rawHtml: (r, key) => (
        <div
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{
                __html: r[key]
            }}
        />
    ),
    name: r => r.name,
    id: r => r.id,
    number: r => r.number,
    images: (r, key) =>
        r[key].map(({ image }, i) => (
            <img key={i.toString()} src={image.thumbnail} alt="" />
        ))

    /*platforms: (r, key) => {
        const { social, zoho_id } = r[key];

        return (
                social ? (
                    <div style={{display:"flex",alignItems:"center"}}><Chip
                        title={Object.keys(social.identities||{})
                            .map(
                                key =>
                                    `${key}: ${social.identities[key].join(
                                        ", "
                                    )}`
                            )
                            .join("\n")}
                        avatar={
                            social.picture ? (
                                <Avatar>
                                    <ThumbnailImage
                                  src={social.picture}
                                     style={{
                                            width: "100%",
                                            height: "100%"
                                        }}
                                />
                                </Avatar>
                            ) : null
                        }
                        label={social.type}
                        style={{
                            color: "white",
                            background:
                                { "facebook.com": "#4C62A3" }[social.type] ||
                                "#000"
                        }}
                    /></div>
                ) : null
        );
    }*/
};
