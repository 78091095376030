import React from "react";

import MarkdownIt from "markdown-it";
import MarkdownItVideo from "markdown-it-video";
import markdownItAnchor from "markdown-it-anchor";

import { useInput, FieldTitle } from "ra-core";
import MdEditor from "react-markdown-editor-lite";
import { FormControl, InputLabel, FormHelperText } from "@material-ui/core";
// import style manually
import "react-markdown-editor-lite/lib/index.css";

import { useNotify } from "react-admin";

import MarkDownInputEmbedPlugin from "./MarkDownInputEmbedPlugin";

import { siteUrl, siteDomain } from "cms-config/domain";

import dataProvider from "cms-config/dataProvider";

import {fileUriToURl, wbrConvert} from "cms-toolbox/helpers/utils"

MdEditor.use(MarkDownInputEmbedPlugin);

const position = {
  false: "push",
  true: "unshift"
};
const mdParser = new MarkdownIt({
  breaks: true
})
  .use(MarkdownItVideo, {
    //  youtube: { width: "100%", height: 195 }
    // vimeo: { width: 500, height: 281 },
    //vine: { width: 600, height: 600, embed: 'simple' },
    //prezi: { width: 550, height: 400 }
  })
  .use(markdownItAnchor, {
    level: 1,
    slugify: s =>
      encodeURIComponent(
        String(s)
          .trim()
          .toLowerCase()
          .replace(/[^\w\s]/gi, "")
          .replace(/\s+/g, "-")
      ),
    permalink: true,
    renderPermalink: (slug, opts, state, idx) => {
      const space = () =>
        Object.assign(new state.Token("text", "", 0), { content: " " });

      const linkTokens = [
        Object.assign(new state.Token("link_open", "span", 1), {
          attrs: [
            ["class", opts.permalinkClass],
            // ["href", opts.permalinkHref(slug, state)],
            [
              "onclick",
              `window.prompt('Use this anchor to link to this header on the same page','#${slug}')`
            ],
            ["title", "Create link to this header"],
            ...Object.entries(opts.permalinkAttrs(slug, state))
          ]
        }),
        Object.assign(new state.Token("html_block", "", 0), {
          content: opts.permalinkSymbol
        }),
        new state.Token("link_close", "a", -1)
      ];

      // `push` or `unshift` according to position option.
      // Space is at the opposite side.
      if (opts.permalinkSpace) {
        linkTokens[position[!opts.permalinkBefore]](space());
      }
      state.tokens[idx + 1].children[position[opts.permalinkBefore]](
        ...linkTokens
      );
    },
    permalinkClass: "header-anchor",
    permalinkSymbol: "¶",
    permalinkBefore: false
  });

function onImageUpload(file) {
  return dataProvider("CREATE", "file", {
    data: {
      file: { new: { rawFile: file } }
    }
  }).then(({ data }) => {
    if (data && data?.file) {
      return fileUriToURl(data.file.uri);
    }
  });

  /*return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = data => {
      resolve(data.target.result);
    };
    reader.readAsDataURL(file);
  });*/
}

export default props => {
  const { resource, source, label, helperText } = props;

  const {
    input: { value, onChange },
    meta: { touched, error },
    isRequired
  } = useInput(props);

  const notify = useNotify();

  const handleEditorChange = ({ html, text }) => {
    const regex = new RegExp(
      `\\]\\([ \\t]*((http(s)?:\\/\\/)?(www\\.)?${siteDomain}(.*?))\\)`,
      "gi"
    );
    const match = text.match(regex);
    if (match) {
      let transforms = [];

      const replaced = text.replace(regex, (...args) => {
        const to = (args[5] || "/").trim();
        transforms.push({ prev: args[1], to });

        return `](${to})`;
      });
      const l = transforms.length;
      if (l) {
        const mult = l > 1;

        notify(
          `${mult ? "Links" : "A link"} containing the site's full url ${
            mult ? "were" : "was"
          } replaced by a root-relative urls: ${transforms
            .map(({ prev, to }) => `${prev} 🠪 ${to}`)
            .join(", ")}`,
          "warning"
        );

        return onChange(replaced);
      }
    }

    onChange(text);
  };

  return (
    <FormControl error={!!(touched && error)} fullWidth={true}>
      {label !== "" && label !== false && (
        <InputLabel shrink>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}

      <MdEditor
        value={value}
        renderHTML={text =>
         mdParser
            .render( wbrConvert(text))
            .replace(
              /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi,
              (full, _, href) => {
                href = href.trim();
                if (href) {
                  if (href[0] === "#") {
                    return "<a";
                  }
                  if (href[0] === "/") {
                    href = siteUrl + href;
                  }
                  return `<a target="_blank" href="${href}"`;
                }
                return full;
              }
            )
        }
        onChange={handleEditorChange}
        /* config={{
          imageAccept:".jpg,.png"
        }}*/
        plugins={[
          "header",
          "font-bold",
          "font-italic",
          //"font-underline",
          "font-strikethrough",
          "list-unordered",
          "list-ordered",
          "block-quote",
          "block-wrap",
          //  'block-code-inline',
          // 'block-code-block',
          "table",
          "image",
          "embed",
          "link",
          "logger",
          "mode-toggle",
          "full-screen"
        ]}
        onImageUpload={onImageUpload}
      />

      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};
