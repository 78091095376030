import * as React from "react";
import {
    Children,
    cloneElement,
    isValidElement,
    useRef,
    ReactElement,
    FC
} from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useTranslate, ValidationError, Record } from "ra-core";
import classNames from "classnames";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { FormInput } from "react-admin";

import Badge from "@material-ui/core/Badge";

import { useField, useForm } from "react-final-form";
import { withStyles } from "@material-ui/core/styles";

const AntTabs = withStyles({
    root: {
        borderBottom: "1px solid #e8e8e8"
    }
    /*indicator: {
        backgroundColor: "#1890ff"
    }*/
})(Tabs);

const AntTab = withStyles(theme => ({
    root: {
        textTransform: "none",
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        overflow: "visible",
        marginRight:0,
        lineHeight:1.3
        /*  '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },*/
    },
    selected: {}
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(
    theme => ({
        root: { },
        line: {padding:"20px 20px 0"},
        tabRow: {
            display: "grid",
            gridTemplateColumns: "minmax(max-content, 150px) 1fr"
        },
        tabLabel: {
            display: "flex",
            alignItems: "center",
            padding: "0 20px 0 10px",
            backgroundColor: "#f0efef"
        },
        index: {
            width: "3em",
            paddingTop: "1em",
            [theme.breakpoints.down("sm")]: { display: "none" }
        },
        form: { flex: 2 },
        action: {
            paddingTop: "0.5em"
        },
        leftIcon: {
            marginRight: theme.spacing(1)
        },
        badge: {
            background: "#00c60099",
            fontSize: "11px",
            padding: "0 5px",
            height: "1.6em"
        }
    }),
    { name: "RaSimpleFormIterator" }
);

const SimpleFormIterator = props => {
    const {
        basePath,
        children,
        fields,
        meta: { error, submitFailed },
        record,
        resource,
        source,
        disableAdd,
        disableRemove,
        variant,
        margin,
        TransitionProps,
        defaultValue,
        variants_source
    } = props;

    const classes = useStyles(props);
    const { input } = useField(variants_source);
    const { change } = useForm();

    const [tab, setTab] = React.useState({});
    //  const [count, setCount] = React.useState(1);

    const [ready, setReady] = React.useState(false);

    React.useEffect(() => {
        let tm = setTimeout(() => {
            console.log("set ready");
            setReady(true);
        }, 100);
        return () => {
            clearTimeout(tm);
        };
    }, []);

    React.useEffect(() => {
        if (ready) {
            setArray();
        }
    }, [input.value, ready]);

    const setArray = () => {
        let groupIds = [];

        if (Array.isArray(input.value)) {
            const build = input.value.reduce((prev, { key, options, name }) => {
                if (!Array.isArray(options) || !key || !name) {
                    return prev;
                }
                const opts = options.filter(o => o.key && o.value);
                if (opts.length < 2) {
                    return prev;
                }
                return opts.map(opt => [`${key}:${opt.key}`, ...prev]);
            }, []);

            var buildList = node => {
                let ret = [];

                if (node.length > 1) {
                    for (let i = 1; i < node.length; i++) {
                        const tails = buildList(node[i]);
                        for (const tail of tails) {
                            ret.push(`${node[0]}-${tail}`);
                        }
                    }
                }

                return ret.length ? ret : [node[0]];
            };

            groupIds = buildList(["", ...build])
                .map(key => key.replace(/^-/, ""))
                .filter(a => a);
        }

        //const newStamp = groupIds.join("|");
        //if (newStamp === window.stamp) {
        //    return;
        //}
        //window.stamp=newStamp;

        ids.current = groupIds.length ? groupIds : ["default:default"];

        const newVals = ids.current.map(fkey => {
            const arr = Array.isArray(fields.value) ? fields.value : [];

            /*const found=arr.find(
                    ({ key }) => fkey === key
                );
if(found){
    return found;
}*/

            const partsSearch = fkey.split("-").sort();

            const similarities = arr.map(({ key }, i) => {
                const parts = key.split("-").sort();

                let ps = [...partsSearch].filter(p => !~parts.indexOf(p));

                return [ps.length, i];
            });
            const ordered = similarities.sort((a, b) => a[0] - b[0]);

            let copyVals = {};
            if (ordered.length && ordered[0][0] <= 1) {
                copyVals = arr[ordered[0][1]];
            }

            /*
return arr.find(
                    ({ key }) => fkey === key
                ) || { key: fkey }
*/
            return { ...copyVals, key: fkey };
        });

        let activeTab = ids.current.find(id => {
            const parts = id.split("-");
            for (const part of parts) {
                const vv = part.split(":");
                if (tab[vv[0]] !== vv[1]) {
                    return false;
                }
            }
            return true;
        });
        if (!activeTab) {
            activeTab = ids.current[0];
        }
        if (activeTab) {
            let set = {};
            const parts = activeTab.split("-");
            for (const part of parts) {
                const vv = part.split(":");
                set[vv[0]] = vv[1];
            }
            console.log(set);
            setTab(set);
        }

        change(source, newVals);
    };

    /* React.useEffect(() => {
        if (stamp === false) {
            return;
        }

        ids.current = stamp.split("|");

        const newVals = ids.current.map(
            fkey =>
                (Array.isArray(fields.value) ? fields.value : []).find(
                    ({ key }) => fkey === key
                ) || { key: fkey }
        );
        console.log("CHANGE!", stamp);
        change(source, newVals);
    }, [stamp]);*/

    // We need a unique id for each field for a proper enter/exit animation
    // so we keep an internal map between the field position and an auto-increment id
    const nextId = useRef(
        fields && fields.length
            ? fields.length
            : defaultValue
            ? defaultValue.length
            : 0
    );

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    const ids = useRef(
        nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
    );

    const removeField = index => () => {
        ids.current.splice(index, 1);
        fields.remove(index);
    };

    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determining if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.
    const disableRemoveField = (record, disableRemove) => {
        if (typeof disableRemove === "boolean") {
            return disableRemove;
        }
        return disableRemove && disableRemove(record);
    };
    const records = get(record, source);
    return fields ? (
        <React.Fragment>
            <div className={classes.root}>
                {Array.isArray(input.value)
                    ? input.value
                          .filter(
                              ({ options, key, name }) =>
                                  key &&
                                  name &&
                                  options &&
                                  options.filter(({ key }) => key).length
                          )
                          .map(({ key, options, name }, i) => {
                              const selIndex = options.findIndex(
                                  o => tab[key] === o.key
                              );
                              return (
                                  <div key={key} className={classes.tabRow}>
                                      <Typography
                                          variant="body1"
                                          className={classes.tabLabel}
                                      >
                                          {name}
                                      </Typography>
                                      <AntTabs
                                          value={
                                              selIndex >= 0 ? selIndex : null
                                          }
                                          onChange={(e, vIndex) => {
                                              setTab({
                                                  ...tab,
                                                  [key]: options[vIndex].key
                                              });
                                          }}
                                          indicatorColor="primary"
                                          textColor="primary"
                                      >
                                          {options
                                              .filter(({ value }) => value)
                                              .map(({ key, value }, i) => {
                                                  return (
                                                      <AntTab
                                                          label={
                                                              i === 0 ? (
                                                                  <Badge
                                                                      classes={{
                                                                          badge:
                                                                              classes.badge
                                                                      }}
                                                                      badgeContent={
                                                                          "default"
                                                                      }
                                                                      color="primary"
                                                                  >
                                                                      {value}
                                                                  </Badge>
                                                              ) : (
                                                                  value
                                                              )
                                                          }
                                                          key={key}
                                                      />
                                                  );
                                              })}
                                      </AntTabs>
                                  </div>
                              );
                          })
                    : null}

                {fields.map((member, index) => {
                    if (
                        !ids.current[index] ||
                        typeof ids.current[index] !== "string"
                    ) {
                        return null;
                    }
                    const tabParts = ids.current[index].split("-");

                    for (let i = 0; i < tabParts.length; i++) {
                        const parts = tabParts[i].split(":");
                        if (tab[parts[0]] !== parts[1]) {
                            return null;
                        }
                    }

                    return (
                        <div key={ids.current[index]} className={classes.line}>

                            {Children.map(children, (input, index2) =>
                                isValidElement(input) ? (
                                    <FormInput
                                        basePath={
                                            input.props.basePath || basePath
                                        }
                                        input={cloneElement(input, {
                                            source: input.props.source
                                                ? `${member}.${
                                                      input.props.source
                                                  }`
                                                : member,
                                            index: input.props.source
                                                ? undefined
                                                : index2,
                                            label:
                                                typeof input.props.label ===
                                                "undefined"
                                                    ? input.props.source
                                                        ? `resources.${resource}.fields.${
                                                              input.props.source
                                                          }`
                                                        : undefined
                                                    : input.props.label
                                        })}
                                        record={
                                            (records && records[index]) || {}
                                        }
                                        resource={resource}
                                        variant={variant}
                                        margin={margin}
                                    />
                                ) : null
                            )}
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    ) : null;
};

export default SimpleFormIterator;
