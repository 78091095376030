import React from "react";
import { SimpleForm, SaveButton, DeleteButton, Toolbar } from "react-admin";

import { Typography } from "@material-ui/core";
import { Edit } from "cms-toolbox/ReactAdminHoc";

import List from "cms-toolbox/List";

import ListContent from "cms-toolbox/ListContent";
import { FieldSet } from "cms-toolbox/FormContent";
import { product as schema } from "mh-schema";

import { getPermissions } from "cms-config/authProvider";

import { DiffTable, FormButtonsBottom } from "cms-toolbox";

import {supplierProductSchema} from "./product"

export class RevisionList extends React.Component {
    render = () => {
        return (
            <List
                title="Revisions"
                {...this.props}
                perPage={10}
                filterDefaultValues={{ type: "suggestion" }}
                actions={<div />}
            >
                <ListContent
                    editAction={true}
                    {...this.props}
                    showFields={[
                        {
                            source: "user_id",
                            media: "small"
                        },
                        {
                            source: "product_id",
                            media: "small"
                        },
                        {
                            source: "created",
                            media: "small",
                            type: "date",
                            label: "Submitted"
                        }
                    ]}
                    schema={[
                        {
                            source: "user_id",
                            type: "reference",
                            label: "Supplier",
                            reference: {
                                resource: "user",
                                optionText: "username"
                            }
                        },
                        {
                            source: "product_id",
                            type: "reference",
                            label: "Product",
                            reference: {
                                resource: "product",
                                optionText: "title"
                            }
                        }
                    ]}
                />
            </List>
        );
    };
}

const RevisionTitle = ({ record }) => {
    return <span>{`Concept: ${record.title}`}</span>;
};

/*
const RevisionToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            size="medium"
            style={{
                color: palette.success
            }}
            label="Approve"
            icon={<ThumbUp />}
            variant="text"
        />
        <DeleteButton
            size="medium"
            style={{
                color: palette.danger
            }}
            label="Reject"
            icon={<ThumbDown />}
        />
    </Toolbar>
);*/

const EntityChanges = props => {
    return <div style={{
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        paddingBottom:15,
        marginBottom:20
    }}>
    <Typography variant="h6" gutterBottom>{'Changes'}</Typography>
    <DiffTable diff={props.record.diff} schema={schema} />
    </div>;
};

export const RevisionEdit = props => {
    const { fullAccess } = getPermissions();
    return (
        <Edit
            title={<RevisionTitle />}
            {...props}
        >
            <SimpleForm
             
                redirect={() => {
                if (window.redirectOverride) {
                    return "/" + window.redirectOverride;
                }

                return false;
            }}
             toolbar={<FormButtonsBottom visitOnWebsite approveRejectMode={fullAccess} />}
            >
                <EntityChanges />
                <FieldSet schemaFragment={fullAccess?schema:supplierProductSchema()} entityId={props.id} />
            </SimpleForm>
        </Edit>
    );
};
