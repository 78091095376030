import React from "react";

import List from "cms-toolbox/List";

import ListContent from "cms-toolbox/ListContent";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { TopToolbar } from "react-admin";

//import { user as schema } from "mh-schema";

const schema = [
    {
        source: "date",
        type: "date"
    },
    {
        source: "message",
        type: "text"
    }
];


const ListActions = props => {
    const { className } = props;

    return <TopToolbar className={className} />;
};

export class ChangelogList extends React.Component {
    render = () => {
        return (
            <List
                title="Changelog"
                {...this.props}
                perPage={1000}
                pagination={null}
                bulkActionButtons={false}
               
                actions={<ListActions />}
            >
                <ListContent
                    {...this.props}
                    schema={schema}
                    showFields={schema.map(field => ({
                        ...field,
                        media: "small"
                    }))}
                    //isTree
                />
            </List>
        );
    };
}
