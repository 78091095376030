const dev = process.env.NODE_ENV && process.env.NODE_ENV === "development";

export const hostname = window.location.host.split(":")[0];

const tld =
	{
	//	"cms-mh.hacktisch.com": "nl"
	}[hostname] ||
	(parts => {
		let tld = [];
		for (let i = parts.length - 1; i >= 0; i--) {
			if (parts[i].length > 3) {
				break;
			}
			tld = [parts[i], ...tld];
		}
		return tld.join(".");
	})(hostname.split("."));

export const region ='nl';
//export const region =dev?'dev':'nl';

const apiBase = dev ? "//localhost:3001" : "https://multihaarden.appspot.com";

export const apiUrl = `${apiBase}/${region}`;

//export const siteDomain=dev?'multihaarden.nl':hostname.replace(/^cms\./,'')
export const siteDomain='multihaarden-hacktisch.vercel.app'

export const siteUrl = dev?'http://localhost:7000':`https://${siteDomain}`;
