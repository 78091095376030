import React from "react";

import { Edit } from "cms-toolbox/ReactAdminHoc";

import { FieldSet } from "cms-toolbox/FormContent";

import { checkout as schema } from "mh-schema";






import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";

export const CheckoutEdit = props => (
	<Edit title={"Checkout page"} {...props} id={"checkout"}>
		
  <TabbedFormHorizontal {...props} redirect={false}  warnWhenUnsavedChanges={false} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <FormTab label="Edit">

			  <FieldSet
            schemaFragment={schema}
        />
		
		</FormTab>
         <EntityRevisionHistory {...props} />
         </TabbedFormHorizontal>
	</Edit>
);
