import React from "react";

import { Edit } from "cms-toolbox/ReactAdminHoc";

import { FieldSet } from "cms-toolbox/FormContent";

import { notfound as schema } from "mh-schema";






import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory } from "cms-toolbox";

export const NotFoundEdit = props => (
	<Edit title={"Error 404 - Not found"} {...props} id={"notfound"}>
		
  <TabbedFormHorizontal {...props} redirect={false}  warnWhenUnsavedChanges={false}>
        <FormTab label="Edit">
			  <FieldSet
            schemaFragment={schema}
        />
		</FormTab>
         <EntityRevisionHistory {...props} />
         </TabbedFormHorizontal>
	</Edit>
);
