import React from "react";
import {
    BooleanInput,
    ReferenceInput,
    NumberInput,
    SelectInput,
    TextInput,
    DateTimeInput,
    ArrayInput,
    DateInput,
    AutocompleteInput,
    ImageInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    PasswordInput,
    CheckboxGroupInput,
    FormTab
} from "react-admin";
import VariantsTable from "cms-toolbox/VariantsTable";
import VariantsTableArrayInput from "cms-toolbox/VariantsTableArrayInput";
import { ColorInput } from "cms-toolbox/ColorInput";
import { AvatarInput } from "cms-toolbox";
import OrderedAutocompleteArrayInput from "cms-toolbox/OrderedAutocompleteArrayInput";
import { Divider, Tab } from "@material-ui/core";
import { useInput } from "ra-core";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import QuickCreate from "./QuickCreate";

import { validators } from "./helpers/form";
//import RichTextInput from "ra-input-rich-text";
import OrderedFormIterator from "./OrderedFormIterator";
import MarkDownInput from "./MarkDownInput";
import SliderInput from "./SliderInput";
import TreeTreeSelect from "./TreeTreeSelect";

import { DivNoProps, ImageEditor } from ".";


const OptionRenderer = choice =>
  choice.record && choice.record.id ? (
    <span>
      <img className="option-image" src={`https://cdn.evenses.com/icons/${choice.record.id}`} />
      {choice.record.id.split(".")[0]}
      
    </span>
  ) : (
    "- none -"
  );



                        const imageAutocompleteProps={

               
              optionText: <OptionRenderer />,
              inputText: choice => choice?choice.id.split(".")[0]:'',
              matchSuggestion: (filterValue, suggestion) => true
                        }


function makeid(length) {
    var result = [];
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(
            characters.charAt(Math.floor(Math.random() * charactersLength))
        );
    }
    return result.join("");
}

export const FieldSet = topProps => {
    const [{ scrollKey, scrollIndex }, setScroll] = React.useState({
        scrollIndex: -1,
        scrollKey: 1
    });

    const {
        source: parentSource,
        schemaFragment,
        tab,
        tabLabel,
        entityId,
        filter = false,

        isSubForm,
        legend,
        tabIndex,
        tabTotal
    } = topProps;

    let schemaUse = schemaFragment.filter(col => {
        if (typeof tab !== "undefined") {
            return col.tab === tab || (!tab && !col.tab);
        }
        if (col.tab === "_NONE_") {
            return false;
        }
        if (!filter || !Array.isArray(filter)) {
            return true;
        }

        return ~filter.indexOf(col.source);
    });

    if (legend) {
        let newSchemaUse = [];
        for (const { image, fields } of legend) {
            newSchemaUse.push({
                type: "legend",
                image
            });
            for (const fieldKey of fields) {
                const foundField = schemaUse.find(
                    ({ source }) => source.split(":").pop() === fieldKey
                );
                if (foundField) {
                    newSchemaUse.push(foundField);
                }
            }
            newSchemaUse.push({
                type: "divider"
            });
        }

        for (const field of schemaUse) {
            if (!newSchemaUse.find(({ source }) => source === field.source)) {
                newSchemaUse.push(field);
            }
        }

        schemaUse = newSchemaUse;
    }

    const ret = schemaUse.map((col, i) => {
        const props = {
            ...(i === 0 && !isSubForm && (!tab || tab === "main")
                ? { autoFocus: true }
                : {}),
            source: (parentSource ? parentSource + "." : "") + col.source,
            label: col.label,
            key: col.source,

            helperText: col.help,
            validate: (col.validate || []).map(v => {
                if (typeof v === "string") {
                    return validators[v];
                }

                return validators[v.type](v);
            }),
            ...col.props
        };
        if(col.hidden){
            props.style={
                ...props.style,
                display:"none"
            }
        }

        const content = (() => {
            switch (col.type) {
                case "legend": {
                    return (
                        <img
                            alt="legend"
                            className="legend-img"
                            {...col.image}
                        />
                    );
                }
                case "divider": {
                    return <Divider style={{ margin: "20px 0" }} />;
                }
                case "array": {
                    /*const amt = (siblingData[col.source] || []).length;
                            const disableRemove =
                                typeof col.min === "number" && amt <= col.min;

                            if (typeof col.min === "number" && amt < col.min) {
                                siblingData[col.source] = [
                                    ...(siblingData[col.source] || []),
                                    ...Array(col.min - amt).fill(null)
                                ];
                            }*/

                    let schemaFragment = col.sub;

                    const { helperText, ...pass } = props;

                    if (col.mode === "variants_table") {
                        return (
                            <VariantsTableArrayInput
                                {...pass}
                              
                                defaultValue={[]}
                            >
                                <VariantsTable
                                    variants_source={col.variants_source}
                                >
                                    <FieldSet
                                        schemaFragment={schemaFragment}
                                        filter={false}
                                        tab={false}
                                        isSubForm={true}
                                    />
                                    {/*} <FormDataConsumer>
                                            {({getSource}) =>
                                            <FieldSet
                                                getSource={getSource}
                                                schemaFragment={schemaFragment}
                                                filter={false}
                                                tab={false}
                                                isSubForm={true}
                                            /> }

                                        </FormDataConsumer>*/}
                                </VariantsTable>
                            </VariantsTableArrayInput>
                        );
                    }

                    let className;

                    let axis = "y";

                    if (col.mode === "mediaplayer") {
                        axis = "xy";
                        className = "grid-array";
                    } else if (col.min === 1 && col.max === 1) {
                        className = "single-element-array";
                        //    Iterator=SimpleFormIterator
                    }

                    const Component =
                        col.xor && false ? FieldSetExclusive : FieldSet;

                    let defaultValue = col.min ? Array(col.min).fill({}) : null;

                    return (
                        <ArrayInput
                            {...pass}
                            className={className}
                            defaultValue={defaultValue}
                        >
                            <OrderedFormIterator
                                min={col.min}
                                max={col.max}
                                axis={axis}
                            >
                                <Component
                                    schemaFragment={schemaFragment}
                                    filter={false}
                                    tab={false}
                                    isSubForm={true}
                                />
                                {/*} <FormDataConsumer>
                                            {({getSource}) =>
                                            <FieldSet
                                                getSource={getSource}
                                                schemaFragment={schemaFragment}
                                                filter={false}
                                                tab={false}
                                                isSubForm={true}
                                            /> }

                                        </FormDataConsumer>*/}
                            </OrderedFormIterator>
                        </ArrayInput>
                    );
                }
                case "boolean":
                    return <BooleanInput {...props} />;

                case "reference":
                    const autocomplete =
                        col.reference.inputType === "autocomplete";

                    let children;
                    if (autocomplete) {




                      const fieldProps = {
                            helperText: col.help,
                            optionText: col.reference.optionText,
                            inputText: col.reference.inputText,
                            matchSuggestion: col.reference.matchSuggestion,

                            shouldRenderSuggestions: value =>
                                value && value.trim().length >= 1,


                            ...(col.reference.images?imageAutocompleteProps:{})    
                        };
                        children = col.multiple ? (
                            col.sortable ? (
                                <OrderedAutocompleteArrayInput
                                    {...fieldProps}
                                />
                            ) : (
                                <AutocompleteArrayInput {...fieldProps} />
                            )
                        ) : (
                            <AutocompleteInput {...fieldProps} />
                        );


/*
                        const fieldProps = {
                            helperText: col.help,
                            optionText: col.reference.optionText,
                            shouldRenderSuggestions: value =>
                                value && value.trim().length >= 1
                        };
                        children = col.multiple ? (
                            col.sortable ? (
                                <OrderedAutocompleteArrayInput
                                    {...fieldProps}
                                />
                            ) : (
                                <AutocompleteArrayInput {...fieldProps} />
                            )
                        ) : (
                            <AutocompleteInput {...fieldProps} />
                        );*/
                    } else {
                        const fieldProps = {
                            helperText: col.help,
                            root: col.root,
                            exclude: entityId || null,
                            optionText: col.reference.optionText
                        };

                        children = col.multiple ? (
                            // <SelectArrayInput {...fieldProps} />
                            <TreeTreeSelect {...fieldProps} multiple={true} />
                        ) : (
                            <TreeTreeSelect {...fieldProps} />
                        );
                    }

                    const containerProps = {
                        ...(col.reference.images
                            ? {}
                            : {
                                  filterToQuery: searchText => ({
                                      [col.reference.optionText]: {
                                          operand: "%" + searchText + "%",
                                          operator: "LIKE"
                                      }
                                  })
                              }),
                        filter: col.filter,
                        perPage: autocomplete ? 10 : 100000,
                        ...props,
                        resource: col.reference.resource,
                        reference: col.reference.resource,
                        children
                    };
                    delete containerProps.helperText;

                    const input = col.multiple ? (
                        <ReferenceArrayInput {...containerProps} />
                    ) : (
                        <ReferenceInput allowEmpty {...containerProps} />
                    );

                    if (col.reference.quickCreate) {
                        return [
                            input,
                            <QuickCreate
                                key="quickCreate"
                                source={props.source}
                                multiple={col.multiple}
                                resource={col.reference.resource}
                            />
                        ];
                    } else {
                        return input;
                    }

                case "image":
                    return (
                        <ImageInput
                            {...props}
                            maxSize={
                                ~~process.env.REACT_APP_MAX_UPLOAD_SIZE || null
                            }
                            source={props.source + ".new"}
                            accept={
                                col.accept
                                    ? Object.keys(col.accept).join(", ")
                                    : "image/*"
                            }
                            placeholder={
                                <ImageEditor
                                    transforms={col.transforms}
                                    col={col}
                                    source={props.source}
                                />
                            }
                        >
                            <React.Fragment />
                        </ImageInput>
                    );

                case "select":
                    const selInp = (
                        <SelectInput
                            {...props}
                            choices={col.choices.map(choice => {
                                if (!choice.icon) {
                                    return choice;
                                }
                                return {
                                    ...choice,
                                    name: (
                                        <span>
                                            <img
                                                alt=""
                                                style={{
                                                    verticalAlign: "middle",
                                                    width: "1ex",
                                                    height: "1ex",
                                                    margin: "0 .6em",
                                                    transform: "scale(3)"
                                                }}
                                                src={choice.icon}
                                            />{" "}
                                            {choice.name}
                                        </span>
                                    )
                                };
                            })}
                        />
                    );

                    if (col.mode === "avatar") {
                        return <AvatarInput {...props}>{selInp}</AvatarInput>;
                    }

                    return selInp;
                case "slider":
                    const { helperText, ...pass } = props;
                    return <SliderInput {...col} {...pass} />;
                case "number":
                    return <NumberInput {...props} />;
                case "date":
                    return <DateInput {...props} />;
                case "markdown":
                    return <MarkDownInput {...props} />;

                case "datetime":
                    return <DateTimeInput {...props} />;
                case "longtext":
                    return (
                        <TextInput
                            {...props}
                            multiline={col.multiline}
                            className="long-text-input"
                            type={col.type}
                        />
                    );
                case "checkboxes":
                    return (
                        <CheckboxGroupInput {...props} choices={col.choices} />
                    );
                case "password":
                    return <PasswordInput {...props} type={col.type} />;
                case "color":
                    return (
                        <ColorInput
                            {...props}
                            defaultColor={col.defaultColor}
                        />
                    );

                default:
                    if (col.defaultFunction === "randId") {
                        props.defaultValue = makeid(5);
                    }
                    return (
                        <TextInput
                            multiline={col.multiline}
                            {...props}
                            type={col.type}
                        />
                    );
            }
        })();

        return (
            <DivNoProps
                key={props.key}
                className={`input-cont input-cont-${col.type}`}
            >
                {content}
            </DivNoProps>
        );
    });
    // .map(f => console.log(f.key)||(rest.schemaFragment ? f : <div key={f.key}>{f}</div>));

    // const ret=<FormDataConsumer>{_fieldSet}</FormDataConsumer>

    if (tab) {
        return (
            <React.Fragment>
                <div
                    className="form-tab-title"
                    style={{
                        "--offset": tabIndex,
                        "--offsetBottom": tabTotal - tabIndex
                    }}
                >
                    <Tab
                        label={tabLabel || tab}
                        color="secondary"
                        onClick={() =>
                            setScroll({
                                scrollIndex: tabIndex,
                                scrollKey: scrollKey + 1
                            })
                        }
                        className="form-tab-title-text"
                    />
                </div>

                <div className="form-tab-content">
                    {scrollIndex === tabIndex ? (
                        <ScrollIntoViewIfNeeded
                            options={{
                                behavior: "smooth",
                                block: "start"
                            }}
                            key={scrollKey}
                            className="form-tab-scroller"
                        >
                            <div />
                        </ScrollIntoViewIfNeeded>
                    ) : null}
                    <FormTab label={tabLabel || tab}>{ret}</FormTab>
                </div>
            </React.Fragment>
        );
    }
    return ret;
};

const FieldSetExclusive = props => {
    const {
        input: { value }
    } = useInput(props);

    let schemaFragment = [...props.schemaFragment];
    const filledFields = Object.keys(value).filter(k => value[k]);
    if (filledFields.length) {
        schemaFragment = schemaFragment.filter(({ source }) =>
            filledFields.includes(source)
        );
    }

    return <FieldSet {...props} schemaFragment={schemaFragment} />;
};
