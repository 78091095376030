import React from "react";
import {
    Responsive,
    Datagrid,
    EmailField,
    TextField,
    FunctionField,
    NumberField,
    ReferenceField,
    BooleanField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ArrayField,
    Link,
    SimpleShowLayout
} from "react-admin";

import { ThumbnailImage, Avatar } from "cms-toolbox";

import { VisitButton, EditButton } from "cms-toolbox/Buttons";

import { ChipList, TreeList } from ".";

import { fileUriToURl } from "cms-toolbox/helpers/utils";

import { renderFunctions, moment } from "cms-config";

import Chip from "@material-ui/core/Chip";

/*
const ExpandPanel = ({ id, record, resource }) => (
    <Labeled label="Email">
     {content}
     </Labeled>
);*/

const EntityLinksField = ({ source, record = {} }) => (
    <div className="entity-links">
        {record[source].map(p => (
            <Link to={p} key={p}>
                <Chip size="small" clickable label={p} />
            </Link>
        ))}
    </div>
);

export default class ListContent extends React.Component {
    renderFields(schema) {
        return schema.map((col, i) => {
            const props = {
                source: col.source,
                label: col.label,
                key: col.source,
                sortable:
                    !~col.source.indexOf(".") &&
                    !~["reference", "image"].indexOf(col.type)
            };

            if (col.render) {
                return renderFunctions[col.render] ? (
                    <FunctionField
                        {...props}
                        render={renderFunctions[col.render]}
                    />
                ) : null;
            }
            switch (col.type) {
                case "date":
                    return (
                        <FunctionField
                            {...props}
                            render={resource => {
                                return moment(resource[props.source]).format(
                                    "lll"
                                );
                            }}
                        />
                    );
                case "array":
                    return (
                        <ArrayField {...props}>
                            <SingleFieldList linkType={false}>
                                {this.listViewRow(col.sub)[0]}
                            </SingleFieldList>
                        </ArrayField>
                    );

                case "reference":
                    if (col.multiple) {
                        return (
                            <ReferenceArrayField
                                allowEmpty
                                reference={col.reference.resource}
                                {...props}
                            >
                                <SingleFieldList>
                                    <ChipField
                                        size="small"
                                        source={col.reference.optionText}
                                    />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        );
                    }
                    return (
                        <ReferenceField
                            allowEmpty
                            reference={col.reference.resource}
                            {...props}
                        >
                            <TextField source={col.reference.optionText} />
                        </ReferenceField>
                    );

                case "select":
                    if (col.mode === "avatar") {
                        return (
                            <FunctionField
                                {...props}
                                render={resource => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Avatar
                                            seed={resource[col.seed]}
                                            gender={resource[props.source]}
                                        />
                                        <div style={{ width: 10 }} />
                                        <ChipList
                                            selected={
                                                resource[props.source] ||
                                                "human"
                                            }
                                            choices={col.choices}
                                            {...props}
                                        />
                                    </div>
                                )}
                            />
                        );
                    }

                    return (
                        <FunctionField
                            {...props}
                            render={record => {
                                return (
                                    <ChipList
                                        selected={record[props.source]}
                                        choices={col.choices}
                                        {...props}
                                    />
                                );
                            }}
                        />
                    );

                case "boolean":
                    return <BooleanField {...props} />;
                case "number":
                    return <NumberField {...props} />;
                case "email":
                    return <EmailField {...props} />;
                case "image":
                    return (
                        <FunctionField
                            {...props}
                            render={record => {
                                if (
                                    !record[props.source] ||
                                    !record[props.source].uri
                                ) {
                                    if(record[props.source]&&record[props.source].url){
                                         return (<ThumbnailImage
                                        src={record[props.source].url}
                                        className="table-img"
                                    />)
                                    }


                                    return null;
                                }
                                const url = fileUriToURl(
                                    record[props.source].uri
                                );
                                if (!url) {
                                    return null;
                                }
                                return (
                                    <ThumbnailImage
                                        src={url}
                                        className="table-img"
                                    />
                                );
                            }}
                        />
                    );

                /*  return (
                        <ImageField
                            className="thumbNailView"
                            {...props}
                            source={props.source + ".thumbnail"}
                        />
                    );*/
                case "entity_links":
                    return <EntityLinksField {...props} />;
                default:
                    return <TextField {...props} />;
            }
        });
    }
    listViewRow(schema) {
        const { editAction, visitAction, showAction, rowStyle } = this.props;
        return (
            <Datagrid rowStyle={rowStyle}>
                {this.renderFields(schema)}

                {editAction && <EditButton />}
                {showAction && <EditButton isShow />}
                {visitAction && <VisitButton />}
            </Datagrid>
        );
    }
    /*
    cols=(fieldList)=>{

         const{  
            editAction,
            visitAction}=this.props

        return `3em ${fieldList.map(({width})=>width?(width*3)+'em':'1fr').join(' ')} ${editAction?'5em':''} ${visitAction?'5em':''}`
    }
*/
    render() {
        if (this.props.isShowView) {
            return (
                <SimpleShowLayout {...this.props}>
                    {this.renderFields(this.props.schema)}{" "}
                </SimpleShowLayout>
            );
        }

        if (this.props.isTree) {
            return (
                <TreeList
                    resource={this.props.resource}
                    schema={this.props.schema}
                />
            );
        }

        const {
            hasShow,
            hasList,
            hasEdit,
            showFields,
            visitAction,
            editAction,
            showAction,
            schema,
            ...rest
        } = this.props;

        const fieldList = showFields.map(field => ({
            ...schema.find(col => col.source === field.source),
            ...field
        }));

        return (
            <Responsive
                {...rest}
                small={this.listViewRow(
                    fieldList.filter(({ media }) => media === "small")
                )}
                medium={this.listViewRow(
                    fieldList.filter(
                        ({ media }) => ~["small", "medium"].indexOf(media)
                    )
                )}
                large={this.listViewRow(fieldList)}
            />
        );
    }
}
