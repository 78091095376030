import React from "react";
import { Admin, Resource, mergeTranslations } from "react-admin";

import englishMessages from "ra-language-english";

/*

import { ReactComponent as StorageIcon } from "icons/resource/storage.svg";
import { ReactComponent as StorageLocationIcon } from "icons/resource/storage-location.svg";
import { ReactComponent as ItemIcon } from "icons/resource/item.svg";
import { ReactComponent as OrderIcon } from "icons/resource/order.svg";
import { ReactComponent as OrderExtraIcon } from "icons/resource/order-extra.svg";
import { ReactComponent as FormatIcon } from "icons/resource/format.svg";
import { ReactComponent as BillingIcon } from "icons/resource/billing.svg";
import { ReactComponent as ItemTransactionIcon } from "icons/resource/item-transaction.svg";
import { ReactComponent as TimeslotIcon } from "icons/resource/timeslot.svg";

*/
import { ReactComponent as UserIcon } from "icons/resource/user.svg";

import { ReactComponent as TreeIcon } from "icons/resource/tree.svg";
import { ReactComponent as SettingsIcon } from "icons/resource/settings.svg";
import { ReactComponent as PageIcon } from "icons/resource/page.svg";
import { ReactComponent as ProductIcon } from "icons/resource/product.svg";
import { ReactComponent as PathIcon } from "icons/resource/path.svg";
import { ReactComponent as ArticleIcon } from "icons/resource/article.svg";
import { ReactComponent as BrokenLinksIcon } from "icons/resource/broken-links.svg";
import { ReactComponent as CustomerIcon } from "icons/resource/shopping-bag.svg";
import { ReactComponent as SuggestionIcon } from "icons/resource/suggestion.svg";

import { ReactComponent as StatisticIcon } from "icons/resource/statistics.svg";
import dataProvider from "cms-config/dataProvider";

//import Statistics from "./pages/Statistics";

import {
    ProductList,
    ProductEdit,
    ProductCreate
} from "cms-config/resources/product";
import { PageList, PageEdit, PageCreate } from "cms-config/resources/page";
import {
    ArticleList,
    ArticleEdit,
    ArticleCreate
} from "cms-config/resources/article";
import { CustomerList } from "cms-config/resources/customer";
import { TreeList, TreeEdit, TreeCreate } from "cms-config/resources/tree";
import { PathList, PathEdit, PathCreate } from "cms-config/resources/path";
import { TimelineList } from "cms-config/resources/timeline";
import { RevisionList, RevisionEdit } from "cms-config/resources/revision";
import { UserList, UserEdit, UserCreate } from "cms-config/resources/user";
import { BrokenLinksList } from "cms-config/resources/broken-links";
import { ChangelogList } from "cms-config/resources/changelog";
import { SettingsEdit } from "cms-config/resources/settings";
import { QuoteRequestedEdit } from "cms-config/resources/pages/quote-requested";
import { CheckoutEdit } from "cms-config/resources/pages/checkout";
import { NotFoundEdit } from "cms-config/resources/pages/not-found";
import { FrontEdit } from "cms-config/resources/pages/front";
import { ContactEdit } from "cms-config/resources/pages/contact";
import { StatisticsList } from "cms-config/resources/statistics";
import {
    BlogEdit,
    PartnersEdit
} from "cms-config/resources/pages/overview-pages";

import { authProvider } from "cms-config";

import { createMuiTheme } from "@material-ui/core/styles";

import ConceptsBadge from "components/layout/ConceptsBadge";
import AppLayout from "components/layout/AppLayout";
import customRoutes from "components/pages/_customRoutes";
import Login from "components/layout/Login";

import Manual from "components/pages/Manual";

import "cms-toolbox/toolbox.sass";
import "./local.css";

import { treeReducer as tree, visitHistoryReducer as visit, revisionAmountReducer as revisionAmount } from "reducers";


import polyglotI18nProvider from "ra-i18n-polyglot";

// This package's translations
import { en as importMessages } from "react-admin-import-csv/lib/i18n";

import { Helmet } from "react-helmet";

import { getPermissions } from "cms-config/authProvider";

// Select locale based on react-admin OR browser
//const locale = useLocale() || resolveBrowserLocale();
// Create messages object
//const messages = {
// Delete languages you don't need
//  en: { ...englishMessages, ...domainMessages }
//};
// Create polyglot provider

const messages = mergeTranslations(englishMessages, importMessages, {
    "Imported %{fname}": "Import successful"
});

const i18nProvider = polyglotI18nProvider(locale => messages, "en");

const headerHeight = 50;
const contentPadTop = 20;
const listToolbarHeight = 64;

const RaEdit = {
    /*root: {
        paddingTop: contentPadTop
    },
    main: {
        marginTop: "0 !important"
    }*/
    main: {
        marginTop: "0 !important"
    },
    card: {
        overflow: "visible",
        minWidth: 0
    }
};

/*
const paperStyle = {
    background: "white !important",
    borderRadius,
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
};*/

const theme = createMuiTheme({
    sidebar: {
        width: 190
    },
    overrides: {
        RaLayout: {
            /*   root:{
                minWidth:0,

            },*/
            content: {
                //minWidth:0,
                // flexGrow:0,
                maxWidth: 1400,
                minWidth: 0,
                //   minWidth:0,
                paddingTop: contentPadTop,
                paddingLeft: 0,
                paddingBottom: 35,
                paddingRight: 35
            },
            appFrame: {
                marginTop: `0 !important`,
                maxWidth: "100vw"
                // marginTop:`${headerHeight}px !important`
            }
        },
        RaEdit,
        RaCreate: RaEdit,
        RaSidebar: {
            root: {
                paddingTop: contentPadTop,
                alignSelf: "flex-start",
                position: "sticky",
                top: headerHeight,

                minHeight: `calc(100vh - ${headerHeight}px)`,
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box"
            },
            drawerPaper: {
                flex: 1,
                justifyContent: "space-between"
            }
        },
        RaList: {
            content: {
                boxShadow: "none",
                background: "none",
                borderRadius: "initial",
                minWidth: 0
            }
        },
        RaFilterFormInput: {
            body: {
                flexShrink: 1,
                minWidth: 0
            }
        },
        RaFilterForm: {
            form: {
                flexWrap: "nowrap",
                minWidth: 0
            }
        },
        RaFormInput: {
            input: {
                width: "max-content"
            }
        },
        RaSimpleFormIterator: {
            index: {
                display: "block !important"
            }
        },
        RaToolbar: {
            toolbar: {
                position: "sticky",
                bottom: 0,
                zIndex: 50
            }
        },

        RaListToolbar: {
            toolbar: {
                /*marginLeft: -offSetHeaderOverlay,
                width: `calc(100% + ${offSetHeaderOverlay * 2}px)`,
                background: "#F0EFEF",
                */ alignItems:
                    "center",
                position: "sticky",

                background: "white",
                top: headerHeight,
                height: listToolbarHeight,
                padding: "0 16px !important",
                minHeight: 0,
                zIndex: 5,
                //padding:"0 5px 0 5px",
                boxSizing: "border-box",
                overflow: "hidden"
                /* marginBottom:-toolbarOffset,
                // background: "yellow",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    width: `calc(100% - ${offSetHeaderOverlay * 2}px)`,
                    left: offSetHeaderOverlay,
                    height: 50 + toolbarOffset,
                    bottom: -50,

                    ...paperStyle
                }*/
            },
            actions: {
                paddingTop: 0,
                paddingBottom: 0,
                minHeight: 0
            }
        },
        MuiAppBar: {
            root: {
                paddingRight: "0 !important"
            },
            positionFixed: {
                position: "sticky"
            }
        },
        MuiToolbar: {
            dense: {
                height: headerHeight
            }
        },
        MuiTableCell: {
            sizeSmall: {
                padding: "6px 16px 6px 8px"
            }
        },

        RaBulkActionsToolbar: {
            toolbar: {
                position: "sticky",
                zIndex: 100,
                top: headerHeight
            }
        },
        RaSingleFieldList: {
            link: {
                maxWidth: "100%"
            }
        },
        RaChipField: {
            chip: {
                maxWidth: "100%"
            }
        },
        RaDatagrid: {
            /*  table:{
                    tableLayout: 'fixed',
                    width:"auto"
            },*/
            rowCell: {
                maxWidth: "20vw"
            },
            headerCell: {
                // backgroundColor:"orange",
                top: headerHeight + listToolbarHeight,
                background: `linear-gradient(rgb(224, 224, 224),rgb(224, 224, 224)) no-repeat bottom`,
                backgroundSize: "100% 1px",
                border: "none"
            }
        },

        /* MuiCard: {
            root: {...paperStyle,minWidth:0}
        },*/

        MuiFilledInput: {
            root: {
                //  backgroundColor:"green"
            }
        }
    },
    palette: {
        text: {
            primary: "#000000",
            secondary: "#666"
        },
        background: {
            default: "#f0efef"
        },
        secondary: {
            //light: "#F3D900",
            main: "#0088CC",
            // dark: will be calculated from palette.primary.main,
            contrastText: "#FFF"
        },
        primary: {
            main: "#214fb8",
            contrastText: "#ffffff"
        }
    },
    typography: {
        fontSize: 15,
        fontFamily: ["Rubik", "Helvetica", "Arial", "sans-serif"].join(",")
    }
});

function title() {
    const name = (window.location.hostname || "")
        .replace(/^cms\./, "")
        .split(".")[0];

    return name[0].toUpperCase() + name.slice(1) + " CMS";
}

const Dashboard=(props)=>{
     const { fullAccess } = getPermissions();
    return fullAccess?<TimelineList {...props}/>:<Manual {...props}/>
}

const App = () => (<Admin
        i18nProvider={i18nProvider}
        customReducers={{ tree, visit, revisionAmount }}
        customRoutes={customRoutes}
        layout={AppLayout}
        theme={theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
     
    >
        {({ fullAccess }) => {
            return [
                <Helmet>
                    <title>{title()}</title>>
                </Helmet>,
                <Resource
                    name="product"
                    options={{ label: "Products" }}
                    icon={ProductIcon}
                    list={ProductList}
                    edit={ProductEdit}
                    create={fullAccess ? ProductCreate : false}
                />,
               
                <Resource
                    name="page"
                    {...fullAccess && {
                        options: { label: "Pages" },
                        list: PageList,
                        edit: PageEdit,
                        create: PageCreate,
                        icon: PageIcon
                    }}
                />,
                   <Resource
                    name="icon"
                    
                />,

                <Resource
                    name="article"
                    {...fullAccess && {
                        options: { label: "Articles" },
                        list: ArticleList,
                        edit: ArticleEdit,
                        create: ArticleCreate,
                        icon: ArticleIcon
                    }}
                />,

                <Resource
                    name="tree"
                    {...fullAccess && {
                        options: { label: "Trees" },
                        list: TreeList,
                        edit: TreeEdit,
                        create: TreeCreate,
                        icon: TreeIcon
                    }}
                />,

                <Resource
                    name="path"
                    {...fullAccess && {
                        options: { label: "URL Paths" },
                        icon: PathIcon,
                        list: PathList,
                        edit: PathEdit,
                        create: PathCreate
                    }}
                />,

                ...(fullAccess
                    ? [
                          <Resource
                              name="broken-links"
                              options={{ label: "Broken links" }}
                              icon={BrokenLinksIcon}
                              list={BrokenLinksList}
                              create={true}
                          />,

                          <Resource
                              name="settings/globals"
                              options={{ label: "Settings" }}
                              list={SettingsEdit}
                              icon={SettingsIcon}
                          />,

                          <Resource
                              name="settings/front"
                              options={{ label: "Front", parent: "page" }}
                              list={FrontEdit}
                          />,
                          <Resource
                              name="settings/contact"
                              options={{ label: "Contact", parent: "page" }}
                              list={ContactEdit}
                          />,
  <Resource
                              name="settings/checkout"
                              options={{ label: "Checkout", parent: "page" }}
                              list={CheckoutEdit}
                          />,
                          <Resource
                              name="settings/request"
                              options={{ label: "Thank you", parent: "page" }}
                              list={QuoteRequestedEdit}
                          />,
                          <Resource
                              name="settings/notfound"
                              options={{ label: "Not found", parent: "page" }}
                              list={NotFoundEdit}
                          />,

                          <Resource
                              name="settings/blog"
                              options={{ label: "Blog", parent: "page" }}
                              list={BlogEdit}
                          />,
                          <Resource
                              name="settings/partners"
                              options={{ label: "Partners", parent: "page" }}
                              list={PartnersEdit}
                          />
                      ]
                    : []),

                <Resource
                    name="user"
                    {...fullAccess && {
                        options: { label: "Users" },
                        icon: UserIcon,
                        list: UserList,
                        create: UserCreate
                    }}
                    edit={UserEdit}
                />,
                <Resource
                    name="timeline"
                    options={{ label: "Activity", parent: "_NONE_" }}
                    list={fullAccess ? TimelineList : null}
                />,
                ...(fullAccess
                    ? [
                          <Resource
                              name="customer"
                              options={{ label: "Customers" }}
                              icon={CustomerIcon}
                              list={CustomerList}
                              //show={CustomerShow}
                          />,
                           <Resource
                              name="statistics"
                              options={{ label: "Statistics" }}
                              icon={StatisticIcon}
                              list={StatisticsList}
                          />,
                      ]
                    : [])
            ];
        }}
    </Admin>
)

export default App;
