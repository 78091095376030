import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Button,
    EditButton as RaEditButton,
    showNotification
} from "react-admin";
import ImageEye from "@material-ui/icons/RemoveRedEye";

import { siteUrl } from "cms-config/domain";

import Hashids from "hashids";
const hashids = new Hashids();

export const VisitButton = connect(
    null,
    {
        showNotification
        //  push
    }
)(
    class extends Component {
        render() {
            const { record, resource, alwaysShown, label, className } = this.props;

            let disabled=false
            if (!record) {
                if(alwaysShown){
                    disabled=true
                }else{
                return null;
            }
            }

            let path;
            if (resource === "revision") {
                path = "concept/" + hashids.encode(1000000 + record.id);
            } else {
                path =
                    record._relations &&
                    record._relations.path &&
                    record._relations.path.length
                        ? record._relations.path[0].path
                        : record.path;
                if (typeof path !== "string") {
                    if (record?.id === "front") {
                        path = "";
                    } else if(!alwaysShown) {
                        return null;
                    }else{
                        disabled=true
                    }
                }
            }




            return (
                <Button
                    disabled={disabled}
                    title={label?null:"View on website"}
                    href={disabled?null:siteUrl + "/" + path}
                    target="_blank"
                    size="medium"
                    label={label}
                    className={className}
                >
                    <ImageEye />
                </Button>
            );
        }
    }
);

export const EditButton = ({ isShow, ...props }) => (
    <RaEditButton
        label={""}
        size="medium"
        {...{ ...props, ...(isShow ? { icon: <ImageEye /> } : {}) }}
    />
);
