import React from "react";


import List from "cms-toolbox/List";


import ListContent from "cms-toolbox/ListContent";
//import { article as schema } from "ev-schema";


export class StatisticsList extends React.Component {
    render = () => {
        return (
            <List
                title="Visitor search query log"
                {...this.props}
                perPage={50}
                  sort={{ field: "count", order: "DESC" }}
                
            >
                <ListContent
                    
                    {...this.props}
                    showFields={[
                        {
                            source: "id",
                            media: "small"
                        },
                        {
                            source: "count",
                            media: "small"
                        },
                       
                    ]}
                    schema={[
 {
      
        source: "id",
        type: "text",
        label:"Search query"
    },
     {
      
        source: "count",
        type: "number"
    }
                        ]}
                />
            </List>
        );
    };
}
