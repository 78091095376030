import React from "react";
import { Filter } from "react-admin";

import { Edit, Create } from "cms-toolbox/ReactAdminHoc";
import { FormButtonsBottom } from "cms-toolbox";

import List from "cms-toolbox/List";
import { SearchInput } from "cms-toolbox/inputHoc";

import { SimpleForm } from "cms-toolbox/FormHoc";
import ListContent from "cms-toolbox/ListContent";
import { FieldSet } from "cms-toolbox/FormContent";

import { path as schema } from "mh-schema";

const PathFilter = props => (
    <Filter {...props}>
        <SearchInput alwaysOn source="q" />
    </Filter>
);

export class PathList extends React.Component {
    render = () => {
        return (
            <List
                filters={<PathFilter />}
                title="Paths"
                {...this.props}
                perPage={20}
                sort={{ field: "length(path)", order: "ASC" }}
                //pagination={null}
            >
                <ListContent
                    editAction
                    visitAction={true}
                    {...this.props}
                    schema={schema}
                    showFields={[
                        { source: "path", media: "small" },
                        { source: "product_id", media: "small" },
                        { source: "page_id", media: "small" },
                        { source: "article_id", media: "small" },
                    ]}
                    //isTree
                />
            </List>
        );
    };
}

const PathTitle = ({ record }) => {
    return <span>{record.id ? `Path ${record.path}` : `New path`}</span>;
};

export const PathEdit = props => (
    <Edit title={<PathTitle />} {...props}>
        <SimpleForm redirect={false} toolbar={<FormButtonsBottom visitOnWebsite />}>
            <FieldSet schemaFragment={schema.filter(a=>a.source==="path")} entityId={props.id} />
        </SimpleForm>
    </Edit>
);

export const PathCreate = props => (
    <Create title={<PathTitle />} {...props}>
        <SimpleForm toolbar={<FormButtonsBottom visitOnWebsite />}>
            <FieldSet schemaFragment={schema} />
        </SimpleForm>
    </Create>
);
