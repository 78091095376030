import React from "react";

import { Edit } from "cms-toolbox/ReactAdminHoc";

import { FieldSet } from "cms-toolbox/FormContent";

import { partners, blog } from "mh-schema";







import { TabbedFormHorizontal } from "cms-toolbox/FormHoc";
import { FormTab, EntityRevisionHistory, FormButtonsBottom } from "cms-toolbox";


const OverViewEdit = ({ title, id, schema, ...props }) => (
	<Edit title={title} {...props} id={id}>
	
  <TabbedFormHorizontal {...props} redirect={false}  warnWhenUnsavedChanges={false} toolbar={<FormButtonsBottom visitOnWebsite />}>
        <FormTab label="Edit">
			<FieldSet schemaFragment={schema} />
		</FormTab>
         <EntityRevisionHistory {...props} />
         </TabbedFormHorizontal>
	</Edit>
);

export const PartnersEdit = props => (
	<OverViewEdit
		{...props}
		title={"Partners list"}
		id={"partners"}
		schema={partners}
	/>
);
export const BlogEdit = props => (
	<OverViewEdit
		{...props}
		title={"Blog (list of articles)"}
		id={"blog"}
		schema={blog}
	/>
);
