import React from "react";

import { Edit, Create } from "cms-toolbox/ReactAdminHoc";

import List from "cms-toolbox/List";
import { Avatar } from "cms-toolbox";

import { SimpleForm } from "cms-toolbox/FormHoc";
import ListContent from "cms-toolbox/ListContent";
import { FieldSet } from "cms-toolbox/FormContent";
import { Typography } from "@material-ui/core";

import { user as schema, structure } from "mh-schema";

import { Filter, BooleanInput, useGetOne, CRUD_GET_ONE } from "react-admin";

import { SelectInput, SearchInput } from "cms-toolbox/inputHoc";

const UserFilter = props => (
    <Filter {...props}>
        <BooleanInput
            alwaysOn
            source="status"
            variant="outlined"
            label="Active users"
        />
        <SelectInput
            alwaysOn
            source="role"
            //label="Tree"
            choices={structure.roles}
        />
        <SearchInput alwaysOn source="q" />
    </Filter>
);

export class UserList extends React.Component {
    render = () => {
        return (
            <List
                title="Users"
                {...this.props}
                perPage={20}
                filters={<UserFilter />}
                filterDefaultValues={{ status: true }}
                //pagination={null}
            >
                <ListContent
                    editAction
                    {...this.props}
                    schema={schema}
                    showFields={[
                        { source: "name", media: "small" },
                        { source: "role" },
                        { source: "gender", media: "small" },
                        { source: "created", media: "medium", type: "date" }
                    ]}
                    //isTree
                />
            </List>
        );
    };
}

const UserTitle = ({ record }) => {
    return (
        <span>
            {record.id
                ? `User "${record.name || record.username || ""}"`
                : "New user"}
        </span>
    );
};

export const UserEdit = props => (
    <Edit title={<UserTitle />} {...props}>
        <SimpleForm redirect={false}>
            <FieldSet schemaFragment={schema} entityId={props.id} />
        </SimpleForm>
    </Edit>
);

export const UserCreate = props => (
    <Create title={<UserTitle />} {...props}>
        <SimpleForm>
            <FieldSet schemaFragment={schema} />
        </SimpleForm>
    </Create>
);

export const UserShowInline = props => {
    const { data: record } = useGetOne("user", props.id, {
        action: CRUD_GET_ONE
    });

    return record ? (
        <div
            style={{
                display: "flex",
                alignItems: "center"
            }}
        >
            <Avatar
                seed={record.name || record.username}
                gender={record.gender}
                size="small"
            />
            <Typography style={{ marginLeft: 5 }}>{record.name}</Typography>
        </div>
    ) : null;
};
