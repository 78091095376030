import * as React from "react";
import { cloneElement, Children, FC, ReactElement } from "react";
import PropTypes from "prop-types";
import { isRequired, FieldTitle, composeValidators, InputProps } from "ra-core";
import { useFieldArray } from "react-final-form-arrays";
import { InputLabel, FormControl } from "@material-ui/core";

/**
 * To edit arrays of data embedded inside a record, <VariantsTableArrayInput> creates a list of sub-forms.
 *
 *  @example
 *
 *      import { VariantsTableArrayInput, SimpleFormIterator, DateInput, TextInput } from 'react-admin';
 *
 *      <VariantsTableArrayInput source="backlinks">
 *          <SimpleFormIterator>
 *              <DateInput source="date" />
 *              <TextInput source="url" />
 *          </SimpleFormIterator>
 *      </VariantsTableArrayInput>
 *
 * <VariantsTableArrayInput> allows the edition of embedded arrays, like the backlinks field
 * in the following post record:
 *
 * {
 *   id: 123
 *   backlinks: [
 *         {
 *             date: '2012-08-10T00:00:00.000Z',
 *             url: 'http://example.com/foo/bar.html',
 *         },
 *         {
 *             date: '2012-08-14T00:00:00.000Z',
 *             url: 'https://blog.johndoe.com/2012/08/12/foobar.html',
 *         }
 *    ]
 * }
 *
 * <VariantsTableArrayInput> expects a single child, which must be a *form iterator* component.
 * A form iterator is a component accepting a fields object as passed by
 * react-final-form-arrays's useFieldArray() hook, and defining a layout for
 * an array of fields. For instance, the <SimpleFormIterator> component
 * displays an array of fields in an unordered list (<ul>), one sub-form by
 * list item (<li>). It also provides controls for adding and removing
 * a sub-record (a backlink in this example).
 *
 * @see https://github.com/final-form/react-final-form-arrays
 */
const VariantsTableArrayInput = ({
    className,
    defaultValue,
    label,
    children,
    record,
    resource,
    source,
    validate,
    variant,
    margin = "dense",
    ...rest
}) => {
    const sanitizedValidate = Array.isArray(validate)
        ? composeValidators(validate)
        : validate;

    const emptyArray = [];
    const fieldProps = useFieldArray(source, {
        //initialValue: emptyArray,
        validate: sanitizedValidate,
        ...rest
    });

    return (
        <FormControl
            fullWidth
            margin="normal"
            className={className}
            {...rest}
            style={{
                border: "1px solid rgb(224 224 224)",

                boxSizing: "border-box"
            }}
        >
            {cloneElement(Children.only(children), {
                ...fieldProps,
                record,
                resource,
                source,
                variant,
                margin
            })}
        </FormControl>
    );
};

export default VariantsTableArrayInput;
